<template>
	<div id="adhibitionOutLetList">
		<div class="el-content mb12">
			<div class="kd-pacel">检索</div>
			<a-space>
				<a-input v-model:value="search.name" placeholder="门店名称" style="width: 240px;"/>
				<a-input v-model:value="search.mobile" placeholder="门店电话" style="width: 240px;"/>
				<a-button type="primary" @click="getOutletList(1,limit)">
					<i class="ri-search-2-line ri-top"></i> 查询
				</a-button>
				<router-link to="/adhibition/outlet/edit">
					<a-button v-has="{action:'/adhibition/outlet/edit'}" type="primary">
						<i class="ri-add-line ri-top"></i>添加门店
					</a-button>
				</router-link>
			</a-space>
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table :pagination="false" :data-source="info.list" row-key="id" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'门店',dataIndex:'name',slots:{customRender:'name'}},
				{title:'电话',dataIndex:'mobile'},
				{title:'地址',dataIndex:'address'},
				{title:'添加时间',dataIndex:'create_time'},
				{title:'是否启用',dataIndex:'outlet_status',slots:{customRender:'outlet_status'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #name="{record}">
					<a-avatar :src="record.logo"></a-avatar>
					<span class="kd-outlet-name">{{record.name}}</span>
				</template>
				<template #outlet_status="{record}">
					<a-tag color="#00CC66" v-if="record.outlet_status==1" :underline="false">启用</a-tag>
					<a-tag color="#999" v-else :underline="false">禁用</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<router-link :to="{path:'/adhibition/outlet/edit' ,query:{id:record.id}}">
							<kd-button type="primary" title="编辑" icon="ri-more-fill" v-has="{action:'/adhibition/outlet/edit'}"></kd-button>
						</router-link>
						<kd-button type="danger" title="删除" icon="ri-delete-bin-line" v-has="{action:'wxapp_marketing_outlet_del'}" @click="deleteOutLet(record.id,1)"></kd-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getOutletList(info.page,e)}"
					@change="(e)=>{getOutletList(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import outletModel from '@/api/addons/outlet'
import commonModel from '@/api/common'
export default{
	setup(){
		const state = reactive({
			search:{ name:'',mobile:'' },
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
		})
		getOutletList(1,state.info.limit)
		function getOutletList(page,limit){
			outletModel.getOutletList(page,limit,state.search,res=>state.info = {limit,...res})
		}

		const deleteOutLet = (id)=>commonModel.deleteDataList(id,39,"确认要删除门店信息吗？").then(()=>{
			getOutletList(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getOutletList,
			deleteOutLet
		}
	}
}
</script>

<style lang="scss">
	.kd-outlet-name{
		position: relative;
		margin-left: 4px;
	}
</style>
